<template>
  <base-modal :title="$t('disabletfa')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-item>
        <ion-text class="m-2" color="moto"><p v-html="$t('disabletfawithemailinstructions')" /></ion-text>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('email') }}</ion-label>
        <ion-input
          type="email"
          :readonly="!emailEditable"
          :disabled="!emailEditable"
          autocomplete="username"
          :placeholder="$t('email')"
          :value="recoveryEmail"
          @ionInput="recoveryEmail = $event.target.value" />
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('sendtfadisableemail') }}</ion-label>
        <ion-button :loading="sendingEmail" :disabled="sendingEmail || (resendEmailTimeout > 0)" @click="sendTFADisableEmail">
          <ion-spinner v-if="sendingEmail" :name="loadingSpinner" />
          {{ $t('sendtfadisableemail') }}<span v-if="resendEmailTimeout > 0" class="ml-1">({{resendEmailTimeout}})</span>
        </ion-button>
        <ion-text v-if="resendEmailTimeout > 0" color="moto">{{ $t('checkyouremail') }}</ion-text>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-input
          type="password"
          autocomplete="off"
          :placeholder="$t('password')"
          @ionInput="password = $event.target.value" />
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tfadisablecode') }}</ion-label>
        <ion-input
          type="text"
          autocomplete="off"
          class="text-3xl"
          max="6"
          :placeholder="$t('tfadisablecode')"
          @ionInput="tfa_disable_code = $event.target.value" />
      </ion-item>
    </ion-content>

    <ion-footer>
      <div class="mx-4 my-4">
        <Error :error="error" />
      </div>
      <ion-button :disabled="loading" expand="full" @click="disableTFA" color="moto" class="my-2">
        <ion-spinner color="primary" v-if="loading" :name="loadingSpinner" />
        {{ $t('disabletfa') }}
      </ion-button>
      <ion-button expand="full" @click="$ionic.modalController.dismiss()">
        <ion-label>{{ $t('cancel') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<style scoped>
  ion-spinner {
    transform: scale(0.6);
  }
</style>
<script>
  import BaseModal from './BaseModal.vue';
  import { showToast, TOAST_SUCCESS } from "@/utils/helpers";
  import {
    DISABLE_TFA_WITH_EMAIL_ACTION,
    GET_USER_BY_TOKEN_ACTION, SEND_TFA_DISABLE_EMAIL_ACTION
  } from "@/store/store-actions";
  import { loadingSpinner } from '@/configs';
  export default {
    props: ['email', 'emailEditable'],
    components: {
      BaseModal,
    },
    data() {
      return {
        loadingSpinner,
        error: null,
        loading: false,
        sendingEmail: false,
        recoveryEmail: this.email,
        tfa_disable_code: null,
        password: null,
        resendEmailTimeout: 0,
        resendEmailTimer: null,
      }
    },
    watch: {
      email(newValue) {
        this.recoveryEmail = newValue
      }
    },
    beforeDestroy() {
      this.disableResendEmailTimer();
    },
    methods: {
      showToast,
      disableResendEmailTimer() {
        this.resendEmailTimeout = 0;

        if (this.resendEmailTimer) {
          clearInterval(this.resendEmailTimer);
        }

        this.resendEmailTimer = null;
      },
      startResendEmailTimer() {
        this.resendEmailTimeout = 60;
        this.resendEmailTimer = setInterval( () => {
          this.resendEmailTimeout--;
          if (this.resendEmailTimeout <= 0) {
            this.disableResendEmailTimer();
          }
        }, 1000)
      },
      async sendTFADisableEmail() {
        this.error = null;
        this.sendingEmail = true;
        try {
          await this.$store.dispatch(SEND_TFA_DISABLE_EMAIL_ACTION, {email: this.recoveryEmail});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.sendingEmail = false;
        }
        this.startResendEmailTimer();
      },
      async disableTFA() {
        this.loading = true;
        this.error = null;
        try {
          await this.$store.dispatch(DISABLE_TFA_WITH_EMAIL_ACTION, {email: this.recoveryEmail, tfa_disable_code: this.tfa_disable_code, password: this.password});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }

        await this.showToast(this.$t('tfaisdisabled'), TOAST_SUCCESS);

        await this.$store.dispatch(GET_USER_BY_TOKEN_ACTION, {fresh: true});
        await this.$ionic.modalController.dismiss()
      }
    }
  }
</script>