<template>
  <ion-page>
    <ion-content class="">
      <div class="flex items-center h-full">
        <div class="flex-1">
          <div class="ion-text-center flex justify-center mb-5">
            <router-link :to="{ name: 'news' }">
              <ion-img src="/img/logo.svg" class="logo" />
            </router-link>
          </div>
          <div class="ion-text-center">
            <ion-text full class="text-2xl ion-text-center">
              {{ $t('login') }}
            </ion-text>
          </div>
          <form @submit.prevent="ignoreDefaultFormSubmit">
            <template v-if="step === 1">
              <div key="step1" class="ion-padding">
                <ion-item>
                  <ion-label position="stacked">
                    {{ $t('email') }}
                  </ion-label>
                  <ion-input
                    type="email"
                    autocomplete="username"
                    :placeholder="$t('email')"
                    :value="login.email"
                    autofocus
                    @ionInput="login.email = $event.target.value"
                    @keyup.enter.prevent="enterEmail"
                  />
                </ion-item>
              </div>

              <div class="ion-padding-horizontal ion-padding-top">
                <Error :error="error" class="ion-padding-bottom" />

                <LoadingButton :loading="loading" :disabled="loading" data-cy="submit" @click="enterEmail">
                  {{ $t('next') }}
                </LoadingButton>
              </div>
            </template>
            <template v-if="step === 2" v-show="!loading">
              <div key="step2" class="ion-padding-horizontal">
                <ion-item>
                  <ion-label position="stacked">
                    {{ $t('loginas') }}
                  </ion-label>
                  <ion-input type="text" :value="login.email" readonly disabled />
                </ion-item>
                <ion-item>
                  <ion-button @click="back()">
                    {{ $t('loginwithadifferentemail') }}
                  </ion-button>
                </ion-item>
                <ion-item class="px-0">
                  <ion-label position="stacked">
                    {{ $t('password') }}
                  </ion-label>
                  <ion-input
                    ref="passwordInput"
                    type="password"
                    :placeholder="$t('password')"
                    :value="login.password"
                    @ionInput="login.password = $event.target.value"
                    @keyup.enter.prevent="!hasTfa ? submitLogin() : focusTfa()"
                  />
                </ion-item>

                <ion-item v-if="hasTfa" class="px-0">
                  <ion-label position="stacked">
                    {{ $t('authenticatorcode') }}
                  </ion-label>
                  <ion-input
                    ref="tfaInput"
                    type="text"
                    class="text-3xl"
                    min="6"
                    max="6"
                    :placeholder="$t('authenticatorcode')"
                    :value="login.tfa_code"
                    @ionInput="onAuthenticatorInput($event)"
                    @keyup.enter.prevent="submitLogin"
                  />
                </ion-item>
              </div>

              <div class="ion-padding-horizontal ion-padding-top">
                <Error :error="error" class="ion-padding-bottom" />

                <LoadingButton :loading="loading" :disabled="loading" data-cy="submit" @click="submitLogin">
                  {{ $t('login') }}
                </LoadingButton>
              </div>
            </template>

            <div class="ion-padding">
              <ion-button expand="full" color="moto" @click="openRegister">
                {{ $t('createanaccount') }}
              </ion-button>
              <ion-grid class="mt-2">
                <ion-row>
                  <ion-col size="12" :size-sm="hasTfa ? 3 : 4" text-left>
                    <router-link :to="{ name: $routeNames.HOME }">
                      <ion-text>{{ $t('backtoapp') }}</ion-text>
                    </router-link>
                  </ion-col>
                  <ion-col size="12" :size-sm="hasTfa ? 3 : 4" text-left text-sm-center>
                    <router-link :to="{ name: 'password-forgotten' }">
                      <ion-text>{{ $t('forgotpassword') }}</ion-text>
                    </router-link>
                  </ion-col>
                  <ion-col v-if="hasTfa" size="12" :size-sm="hasTfa ? 3 : 4" text-left text-sm-center>
                    <a href="#" @click.prevent="showDisableTFAWithEmailModal">
                      <ion-text>{{ $t('lostauthenticator') }}</ion-text>
                    </a>
                  </ion-col>
                  <ion-col size="12" :size-sm="hasTfa ? 3 : 4" text-left text-sm-right>
                    <router-link :to="{ name: $routeNames.RESEND_VERIFICATION_MAIL }">
                      <ion-text>{{ $t('resendverificationmail') }}</ion-text>
                    </router-link>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { HAS_TFA_ENABLED_ACTION, LOGIN_USER_ACTION } from '@/store/store-actions';
  import DisableTFAWithEmailModal from "@/components/modals/DisableTFAWithEmailModal.vue";

  export default {
    props: {
      nextUrl: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        step: 1,
        hasTfa: false,
        login: {
          email: '',
          password: '',
          tfa_code: '',
        },
        loading: false,
        error: null,
        loginSuccess: '',
      }
    },
    methods: {
      focusElement(refName) {
        this.$nextTick(() => {
          setTimeout( () => {
            requestAnimationFrame(() => {
              this.$refs[refName].setFocus()
            })
          }, 100);
        });

      },
      focusTfa() {
        this.focusElement('tfaInput');
      },
      onAuthenticatorInput($event) {
        const lastLength = this.login.tfa_code.length;

        this.login.tfa_code = $event.target.value

        const currentLength = this.login.tfa_code.length;
        if ((currentLength === 6) && (lastLength != currentLength) && (this.login.password.length)) {
          this.submitLogin();
        }
      },
      back() {
        this.step = 1;
        this.login.password = '';
        this.login.tfa_code = '';
      },
      async enterEmail() {
        this.error = null;
        this.loading = true;
        try {
          this.hasTfa = await this.$store.dispatch(HAS_TFA_ENABLED_ACTION, {email: this.login.email});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }
        this.step = 2;
        this.focusElement('passwordInput');
      },
      ignoreDefaultFormSubmit() {
        return false;
      },
      openRegister() {
        this.$router.push({name: this.$routeNames.REGISTER})
      },
      clearLogin() {
        this.login = {
          email: '',
          password: '',
          tfa_code: null,
        }
      },
      async submitLogin() {
        this.error = null;
        this.loading = true;
        const login = Object.assign({}, this.login);

        try {
          await this.$store.dispatch(LOGIN_USER_ACTION, login);
        } catch (error) {
          this.error = error;
          return;
        } finally {
          this.loading = false;
        }

        this.clearLogin();

        if (this.nextUrl) {
          await this.$router.push(this.nextUrl).catch(() => null);
          return;
        }

        await this.$router.push({name: this.$routeNames.PROFILE}).catch(() => null);

      },
      async showDisableTFAWithEmailModal() {
        const modal = await this.$ionic.modalController.create({
          component: DisableTFAWithEmailModal,
          componentProps: {
            parent: this,
            propsData: {
              email: this.login.email,
              emailEditable: true,
            }
          },
        });

        modal.onDidDismiss().then(() => {
          this.$router.go(0);
        });

        return modal.present();
      },
    }
  }
</script>

<style scoped>
  .background {
    background-color: var(--ion-color-secondary);
  }
</style>
